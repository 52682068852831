.formContentBox[data-v-5038fab8] {
  height: calc(100% - 76px);
}
.formMain[data-v-5038fab8] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.formTopic[data-v-5038fab8] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.shuttleBackBox[data-v-5038fab8] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  overflow: visible;
}
.shutleBack[data-v-5038fab8] {
  width: 33%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.leftRightBtn[data-v-5038fab8] {
  margin: 211px 12px;
}
.shutleTitle[data-v-5038fab8] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.searchInput[data-v-5038fab8] {
  width: calc(100% - 12px);
  padding: 6px 6px 0 6px;
}
.shutContent[data-v-5038fab8] {
  padding: 6px;
  height: 534px;
  overflow: auto;
}
.deptBox[data-v-5038fab8] {
  height: 40px;
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.unitBox[data-v-5038fab8] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.unitTitle[data-v-5038fab8] {
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  font-size: 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.weekBigBox[data-v-5038fab8] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.leftWeek[data-v-5038fab8] {
  width: 100px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-right: 1px solid #e4e4e4;
}
.middleMonth[data-v-5038fab8] {
  width: 100px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  text-align: center;
  border-right: 1px solid #e4e4e4;
  height: 308px;
  overflow-y: auto;
}
.rightWeek[data-v-5038fab8] {
  width: calc(100% - 113px);
}
.rightMonth[data-v-5038fab8] {
  width: calc(100% - 113px);
  height: 250px;
  overflow-y: auto;
}
.thisWeek[data-v-5038fab8] {
  width: 100%;
  text-align: left;
  height: 28px;
  line-height: 28px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
}
.thisWeek[data-v-5038fab8]:hover {
  color: #2979FF;
}
.weekLittleBox[data-v-5038fab8] {
  width: 100%;
  text-align: center;
  height: 34px;
  line-height: 34px;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
  border-bottom: 1px solid #e4e4e4;
}
.weekLittleBox[data-v-5038fab8]:hover {
  color: #2979FF;
}
.weekLittleBox[data-v-5038fab8]:last-child {
  border-bottom: none;
}
[data-v-5038fab8] .el-tabs__header {
  margin: 0;
  border-bottom: none !important;
}
.additionalText[data-v-5038fab8] {
  color: #333;
}